<template>
  <VImg
    v-show="icons[target]"
    max-width="20"
    min-width="20"
    :src="icons[target]"
  />
</template>

<script>
import chocoIcon from '@/assets/icons/choco.svg';
import webIcon from '@/assets/icons/web.svg';
import mobileWebIcon from '@/assets/icons/mobile-web.svg';
import iosIcon from '@/assets/icons/ios.svg';
import androidIcon from '@/assets/icons/android.svg';

export default {
  props: {
    target: {
      type: String, // 'choco', 'web', 'mobile-web', 'ios', 'android'
      required: true,
    },
  },
  data() {
    return {
      icons: {
        choco: chocoIcon,
        web: webIcon,
        'mobile-web': mobileWebIcon,
        ios: iosIcon,
        android: androidIcon,
      },
    };
  },
};
</script>
