<template>
  <VCard class="pa-10">
    <p class="section-title mb-4">
      {{ `${chip.id ? 'Редактировать' : 'Создать'} чипс` }}
    </p>
    <VForm ref="form">
      <div class="form__header">
      <div class="w-full">
        <span>Выберите статус</span>
        <VSelect
          v-model="chip.isActive"
          background-color="#FAFAFB"
          class="select subtitle-1 fixed-width"
          color="#E43539"
          flat
          hide-details
          item-color="red"
          item-text="title"
          item-value="value"
          :items="states"
          solo
        />
      </div>
      <div class="w-full">
        <span>Выберите тип</span>
        <VSelect
          v-model="chip.entity_type"
          background-color="#FAFAFB"
          class="select subtitle-1 fixed-width"
          color="#E43539"
          flat
          hide-details
          item-color="red"
          item-text="title"
          item-value="value"
          :items="entity_types"
          solo
        />
      </div>
      </div>
      <div class="d-flex mt-4">
        <VTextField
          v-model="chip.ruTitle"
          class="mr-2"
          color="black"
          label="Название чипс Рус"
          :rules="rules.title"
        />
        <VTextField
          v-model="chip.kzTitle"
          class="ml-2"
          color="black"
          label="Название чипс Қаз"
          :rules="rules.title"
        />
      </div>
      <VTextField
        v-model.number="chip.sortPosition"
        v-mask="'###'"
        class="fixed-width"
        color="black"
        label="Укажите позицию (0-999)"
      />
      <div class="d-flex justify-space-between mt-5">
        <VInput
          v-model="chip.rating"
          class="subtitle-1 font-weight-bold"
          hide-details="auto"
          :rules="rules.rating"
        >
          Укажите количество звезд:
        </VInput>
        <div class="d-flex align-center">
          <VRating
            v-model="chip.rating"
            background-color="#A4A2B7"
            class="mr-2"
            color="#FFB340"
            dense
            empty-icon="mdi-star"
            hover
            size="30"
          />
          <span
            class="subtitle-1 text-end"
            style="width: 70px"
          >
            {{ ratingTitle }}
          </span>
        </div>
      </div>
      <VDivider class="mt-1 mb-9" />
      <div>
        <VSwitch
          v-model="allTargetsSelected"
          class="mb-3"
          color="#E43539"
          hide-details
          :ripple="false"
          @change="updateTargets"
        >
          <template v-slot:label>
            <span class="subtitle-1 font-weight-bold black--text">
              Доступно на всех платформах
            </span>
          </template>
        </VSwitch>
        <VCheckbox
          v-for="target in targets"
          :key="target.id"
          v-model="chip.targets"
          class="light-grey-checkbox mt-2"
          color="#E43539"
          hide-details
          :ripple="false"
          :rules="rules.targets"
          :value="target.id"
          @change="updateAllTargetsSelected"
        >
          <template v-slot:label>
            <TargetIcon :target="target.value" />
            <span class="subtitle-1 black--text ml-2">
              {{ target.title }}
            </span>
          </template>
        </VCheckbox>
      </div>
    </VForm>
    <div class="d-flex mt-6 full-width justify-end">
      <VBtn
        class="red--text font-weight-bold py-4 subtitle-1 mr-4 br-8"
        height="40"
        text
        @click="$emit('close')"
      >
        Отменить
      </VBtn>
      <VBtn
        class="white--text font-weight-bold py-4 subtitle-1 elevation-0 br-8"
        color="#E43539"
        height="40"
        @click="save"
      >
        {{ chip.id ? 'Сохранить' : 'Создать' }}
      </VBtn>
    </div>
  </VCard>
</template>

<script>
import { createChip, updateChip } from '@/api/api';
import TargetIcon from '@/components/reusable/TargetIcon.vue';

export default {
  components: { TargetIcon },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    targets: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'update'],
  data() {
    return {
      chip: {
        ruTitle: '',
        kzTitle: '',
        isActive: true,
        sortPosition: null,
        rating: 0,
        entity_type: 'restaurant',
        targets: [],
      },
      states: [
        { title: 'Активирован', value: true },
        { title: 'Деактивирован', value: false },
      ],
      entity_types: [
        { title: 'На заведение', value: 'restaurant' },
        { title: 'На доставку', value: 'delivery' },
      ],
      allTargetsSelected: false,
      rules: {
        title: [
          (v) => !!v.length || 'Введите название',
          (v) => (v && v.length <= 40) || 'Название превысило 40 символов',
        ],
        rating: [(v) => v >= 1 || 'Необходимо указать количество звезд'],
        targets: [(v) => !!v.length || 'Выберите платформы'],
      },
    };
  },
  mounted() {
    if (Object.keys(this.value)) {
      this.chip = { ...JSON.parse(JSON.stringify(this.value)) };
    }
    this.chip.targets = this.chip.targets.map((target) => target.id);
    this.updateAllTargetsSelected();
  },
  computed: {
    ratingTitle() {
      const titles = {
        0: 'звезд',
        1: 'звезда',
        2: 'звезды',
        3: 'звезды',
        4: 'звезды',
        5: 'звезд',
      };
      return `${this.chip.rating} ${titles[this.chip.rating]}`;
    },
  },
  methods: {
    updateTargets() {
      this.chip.targets = this.allTargetsSelected
        ? this.targets.map((target) => target.id)
        : [];
    },
    updateAllTargetsSelected() {
      this.allTargetsSelected = this.chip.targets.length === this.targets.length;
    },
    save() {
      this.chip.ruTitle = this.chip.ruTitle.replace(/^\s+/g, '');
      this.chip.kzTitle = this.chip.kzTitle.replace(/^\s+/g, '');
      console.log(this.chip);
      if (!this.$refs.form.validate()) {
        return;
      }
      if (this.chip.id) {
        updateChip(this.chip).then(() => {
          this.$emit('update');
        });
      } else {
        createChip(this.chip).then(() => {
          this.$emit('update');
        });
      }
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/main.scss";

.fixed-width {
  max-width: 100% !important;
}

.v-text-field {
  max-width: 50%;
}

::v-deep {
  .v-list-item--link {
    margin: 0 8px;
    padding: 0 8px;

    &:hover {
      border-radius: 4px;
      background-color: #FFEEEE !important;
    }

    &:before {
      background-color: #FFEEEE !important;
    }
  }

  .v-list-item--active::before {
    opacity: 0 !important;
  }

  .v-input--is-disabled {
    opacity: 1;
  }

  .mdi-chevron-down {
    font-size: 20px;
  }
}

.form__header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}
</style>
