<template>
  <div class="pt-8">
    <div class="d-flex justify-space-between mb-10 px-8">
      <div>
        <p class="section-title mb-1">
          Справочник чипс
        </p>
        <span class="subtitle-1">
          Справочник подсказок, который отображается на странице отзывов для юзера.
        </span>
      </div>
      <VBtn
        class="elevation-0 white--text subtitle-2"
        color="#46C01B"
        height="48"
        @click="showChipDialog()"
      >
        <VIcon
          class="mr-2"
          v-text="'mdi-plus'"
        />
        Создать чипс
      </VBtn>
      <VDialog
        v-model="chipDialog"
        max-width="640"
      >
        <Chip
          :key="key"
          :targets="targets"
          :value="selectedChip"
          @close="chipDialog = false"
          @update="getChips"
        />
      </VDialog>
    </div>
    <UniversalTable
      ref="table"
      :headers="chipsHeaders()"
      :items="chips"
      show-pages
      :top-margin="252"
      @deactivate="deactivateChip"
      @edit="showChipDialog"
      @update-page="getChips"
    >
      <template v-slot:filters>
        <tr>
          <th>
            <VTextField
              v-model="filters.chipId"
              class="ml-4 mr-6 font-weight-medium"
              dense
              hide-details
              outlined
              placeholder="Поиск по ID"
              style="min-width: 100px"
            />
          </th>
          <th>
            <VTextField
              v-model="search.titleRu"
              class="mr-6 font-weight-medium min-width"
              clearable
              dense
              hide-details
              outlined
              placeholder="Поиск по названию"
            />
          </th>
          <th>
            <VTextField
              v-model="search.titleKz"
              class="mr-6 font-weight-medium min-width"
              clearable
              dense
              hide-details
              outlined
              placeholder="Поиск по названию"
            />
          </th>
          <th>
            <VSelect
              v-model="filters.rating"
              class="pl-4 font-weight-medium"
              dense
              hide-details
              item-color="red"
              item-text="title"
              item-value="value"
              :items="ratings"
              outlined
              style="max-width: 170px;"
            />
          </th>
          <th>
            <VSelect
              v-model="filters.entity_type"
              class="mr-6 ml-4 font-weight-medium"
              dense
              hide-details
              item-color="red"
              item-text="title"
              item-value="value"
              :items="entity_types"
              outlined
            />
          </th>
          <th />
          <th />
          <th>
            <VSelect
              v-model="filters.targetId"
              class="mr-6 ml-4 font-weight-medium"
              dense
              hide-details
              item-color="red"
              item-text="title"
              item-value="id"
              :items="targetsFilter"
              outlined
            />
          </th>
          <th />
        </tr>
      </template>
    </UniversalTable>
  </div>
</template>

<script>
import { fetchChips, updateChip, getTargetList } from '@/api/api';
import UniversalTable from '@/components/reusable/UniversalTable.vue';
import { chipsHeaders } from '@/conditions/tablesHeaders';
import Chip from '@/components/Chip.vue';

export default {
  components: { Chip, UniversalTable },
  data() {
    return {
      chips: [],
      chipsHeaders,
      chipDialog: false,
      selectedChip: null,
      key: 0,
      search: {
        titleRu: '',
        titleKz: '',
      },
      filters: {
        chipId: null,
        rating: null,
        targetId: null,
        entity_type: null,
      },
      ratings: [
        { title: 'Все', value: null },
        { title: '1 звезда', value: 1 },
        { title: '2 звезды', value: 2 },
        { title: '3 звезды', value: 3 },
        { title: '4 звезды', value: 4 },
        { title: '5 звезд', value: 5 },
      ],
      entity_types: [
        { title: 'Все', value: null },
        { title: 'На заведение', value: 'restaurant' },
        { title: 'На доставку', value: 'delivery' },
      ],
      targets: [],
      targetsFilter: [{ id: null, title: 'Все' }],
      timeout: null,
    };
  },
  mounted() {
    this.getChips();
    this.fetchTargetList();
  },
  methods: {
    getChips(page = 1) {
      const params = {
        page,
        limit: 20,
        ru_title: this.search.titleRu,
        kz_title: this.search.titleKz,
        chip_id: this.filters.chipId,
        target_id: this.filters.targetId,
        rating: this.filters.rating,
        entity_type: this.filters.entity_type,
      };
      fetchChips(params).then((response) => {
        this.chips = response.reviews || [];
        this.$refs.table.page = response.current_page || 1;
        this.$refs.table.totalPage = response.total_pages === 0 ? 1 : response.total_pages;
        this.$refs.table.totalCount = response.total_count;
      });
    },
    showChipDialog(chip = null) {
      this.key += 1;
      this.selectedChip = chip;
      this.chipDialog = true;
    },
    deactivateChip(chip) {
      const chipToDeactivate = chip;
      chipToDeactivate.isActive = false;
      chipToDeactivate.targets = chipToDeactivate.targets.map((target) => target.id);
      updateChip(chipToDeactivate).then(() => {
        this.getChips();
      });
    },
    fetchTargetList() {
      getTargetList().then((result) => {
        this.targets = result
          .map((item) => ({ title: item.title, value: item.code, id: parseInt(item.pk, 10) }));
        this.targetsFilter = [...this.targetsFilter, ...this.targets];
      });
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getChips();
      },
    },
    search: {
      deep: true,
      handler() {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.getChips();
        }, 500);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/main.scss";

.subtitle-1 {
  line-height: 22px;
}

.min-width {
  min-width: 140px;
}

::v-deep {
  .v-text-field.v-text-field--enclosed {
    padding-top: 0 !important;
  }

  .v-input__slot {
    padding-right: 4px !important;
  }
  .v-select__selection {
    max-width: 99%;
  }
  .v-list-item--link {
    margin: 0 8px;
    padding: 0 8px;

    &:hover {
      border-radius: 4px;
      background-color: #FFEEEE !important;
    }
    &:before {
      background-color: #FFEEEE !important;
    }
  }

  .v-list-item--active::before {
    opacity: 0 !important;
  }
  .v-input--is-disabled {
    opacity: 1;
  }
  .mdi-chevron-down {
    font-size: 20px;
  }

  .v-data-table table {
    thead tr:nth-of-type(even) th, tbody tr:nth-of-type(even), tbody tr:nth-of-type(even):hover  {
      background-color: #E5E5E5 !important;
    }
    thead tr:nth-of-type(odd) th, tbody tr:nth-of-type(odd), tbody tr:nth-of-type(odd):hover {
      background-color: #F1F1F1 !important;
    }
    tbody tr td {
      height: 50px;
    }
  }
  .v-input__icon--clear .v-icon--disabled {
    visibility: visible;
    margin-right: 4px;
  }

  .v-dialog {
    border-radius: 16px;
  }
}
</style>
